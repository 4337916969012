import { AuthConfig, OAuthModule, OAuthStorage } from 'angular-oauth2-oidc';
import { ToastrModule } from 'ngx-toastr';

import { HttpClient } from '@angular/common/http';
import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

import { environment } from '../environments/environment';
import { CoreModule } from './_core/core.module';
// import { AdminGuard } from './_core/guards/admin.guard';
import { AuthGuard } from './_core/guards/auth.guard';
import { CanAccessGuard } from './_core/guards/can-access.guard';
import { FaqsGuard } from './_core/guards/faqs.guard';
import { FundAccessGuard } from './_core/guards/fund-access.guard';
// import { NoAuthGuard } from './_core/guards/no-auth.guard';
import { TemporaryUserGuard } from './_core/guards/temporary-user.guard';
import { KeycloakService } from './_core/services/keycloak.service';
import { SharedModule } from './_shared/shared.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgIdleKeepaliveModule.forRoot(),
    ToastrModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    CoreModule,
    SharedModule,
    FontAwesomeModule,
    OAuthModule.forRoot(),
  ],
  providers: [
    AuthGuard,
    // NoAuthGuard,
    CanAccessGuard,
    FaqsGuard,
    FundAccessGuard,
    TemporaryUserGuard,
    // AdminGuard,
    {
      provide: OAuthStorage,
      useFactory: () => localStorage,
    },
    {
      provide: AuthConfig,
      useValue: environment.authConfig,
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (keycloakService: KeycloakService) => () => keycloakService.init(),
      deps: [KeycloakService],
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}

export function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, '../assets/i18n/', '.json');
}
