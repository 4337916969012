export enum Modals {
  DYNAMIC_CONTENT_MODAL,
  ADD_CHARITY,
  ADD_COUNTRY,
  AUTO_LOGOUT,
  CHOOSE_INSTANCE,
  EMBEDDED_DONATE_BUTTON,
  EDIT_DONATE_BUTTON,
  CANCEL_SUBSCRIPTION,
}

export enum ModalResponse {
  ADD_COUNTRY_RESPONSE,
}
