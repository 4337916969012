import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderComponent } from './header/header.component';
import { NgbDropdownModule, NgbNavModule } from '@ng-bootstrap/ng-bootstrap';
import { ValidationMessageComponent } from './validation-message/validation-message.component';
import { MainAssetSummaryComponent } from './summary/main-asset-summary/main-asset-summary.component';
import { FundsComponent } from './funds/funds.component';
import { ModalContainerComponent } from './_modals/_container/container.component';
import { DynamicContentComponent } from './_modals/dynamic-content/dynamic-content.component';
import { StepperComponent } from './stepper/stepper/stepper.component';
import { StepComponent } from './stepper/step/step.component';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { OwlDateTimeModule } from 'ng-pick-datetime';
import { OwlNativeDateTimeModule } from 'ng-pick-datetime';
import { AddCharityComponent } from './_modals/add-charity/add-charity.component';
import { NoFundsComponent } from './no-funds/no-funds.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { PaginationFrontComponent } from './pagination-front/pagination-front.component';
import { AutoLogoutComponent } from './_modals/auto-logout/auto-logout.component';
import { FaqComponent } from './faq/faq.component';
import { OtherAssetsSummaryComponent } from './summary/other-assets-summary/other-assets-summary.component';
import { CurrencyDropdownComponent } from './currency-dropdown/currency-dropdown.component';
import { EmbeddedDonateButtonComponent } from './_modals/embedded-donate-button/embedded-donate-button.component';
import { EditDonateButtonComponent } from './_modals/edit-donate-button/edit-donate-button.component';
import { FooterComponent } from './footer/footer.component';
import { LayoutModule } from '@angular/cdk/layout';
import { TranslateModule } from '@ngx-translate/core';
import { TruncateCharactersPipe } from './pipes/truncate.pipe';
import { UppercaseFirstLetterPipe } from './pipes/uppercase-first-letter.pipe';
import { SortByPipe } from './pipes/sort.pipe';
import { ToFloorPipe } from './pipes/to-floor.pipe';
import { FilterCurrencyPipe } from './pipes/filter-currency.pipe';
import { FormatTextDirective } from './directives/format-text.directive';
import { ProjectFundNumberPipe } from './pipes/project-fund-number.pipe';
import { SafeHTMLPipe } from './pipes/safe-html.pipe';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TextInputComponent } from './text-input/text-input.component';
import { NgxCurrencyModule } from 'ngx-currency';
import { AddCountryComponent } from './_modals/add-country/add-country.component';
import { CustomCheckboxComponent } from './custom-checkbox/custom-checkbox.component';
import { TextEditorComponent } from './text-editor/text-editor.component';
import { QuillModule } from 'ngx-quill';
import { LoadingSpinnerComponent } from './loading-spinner/loading-spinner.component';
import { TranslateCutPipe } from './pipes/translate-cut.pipe';
import { ConfirmationComponent } from './_modals/confirmation/confirmation.component';
import { ToSnakeCasePipe } from './pipes/to-snake-case.pipe';
import { AppSwitcherComponent } from './header/app-switcher/app-switcher.component';
import { TransformPipe } from './pipes/transform.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    NgbDropdownModule,
    NgbNavModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    PopoverModule.forRoot(),
    BsDropdownModule.forRoot(),
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    LayoutModule,
    TranslateModule,
    NgxCurrencyModule,
    QuillModule.forRoot(),
  ],
  declarations: [
    HeaderComponent,
    FundsComponent,
    MainAssetSummaryComponent,
    AddCharityComponent,
    DynamicContentComponent,
    AutoLogoutComponent,
    ModalContainerComponent,
    StepComponent,
    StepperComponent,
    PaginationFrontComponent,
    ValidationMessageComponent,
    FaqComponent,
    NoFundsComponent,
    PageNotFoundComponent,
    OtherAssetsSummaryComponent,
    CurrencyDropdownComponent,
    EmbeddedDonateButtonComponent,
    EditDonateButtonComponent,
    FooterComponent,
    TruncateCharactersPipe,
    UppercaseFirstLetterPipe,
    SortByPipe,
    ToFloorPipe,
    FilterCurrencyPipe,
    FormatTextDirective,
    ProjectFundNumberPipe,
    SafeHTMLPipe,
    ClickOutsideDirective,
    TextInputComponent,
    AddCountryComponent,
    CustomCheckboxComponent,
    TextEditorComponent,
    LoadingSpinnerComponent,
    TranslateCutPipe,
    ConfirmationComponent,
    ToSnakeCasePipe,
    AppSwitcherComponent,
    TransformPipe,
  ],
  exports: [
    HeaderComponent,
    FundsComponent,
    MainAssetSummaryComponent,
    ModalContainerComponent,
    ValidationMessageComponent,
    StepComponent,
    StepperComponent,
    PaginationFrontComponent,
    OtherAssetsSummaryComponent,
    CurrencyDropdownComponent,
    FooterComponent,
    LayoutModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    TranslateModule,
    FormatTextDirective,
    TruncateCharactersPipe,
    UppercaseFirstLetterPipe,
    SortByPipe,
    ToFloorPipe,
    FilterCurrencyPipe,
    FormatTextDirective,
    ProjectFundNumberPipe,
    SafeHTMLPipe,
    TextInputComponent,
    CustomCheckboxComponent,
    TextEditorComponent,
    ReactiveFormsModule,
    FormsModule,
    LoadingSpinnerComponent,
    TranslateCutPipe,
    ToSnakeCasePipe,
    AppSwitcherComponent,
    TransformPipe,
  ],
})
export class SharedModule {}
