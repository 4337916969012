import { forkJoin, Observable } from 'rxjs';

import { HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CheckContributionPayload } from '../../../app/pages/donate/donate-form/donate-form.model';
import { environment } from '../../../environments/environment';
import { DonateButton } from '../models/donateButton';
import { CharityProject, CopyToMyInstancePayload } from '../models/SearchCharity';
import { ApiService } from '../services/api.service';

@Injectable()
export class TransactionService {
  private readonly resourceUrl = '/transaction';
  private readonly userResourceUrl = '/user';

  constructor(private apiService: ApiService) {}

  public getFundBalances(fundId: string): Observable<any> {
    const params = new HttpParams().set('cseg5', fundId);
    return this.apiService.get(this.resourceUrl + '/get-fund-balances', params);
  }

  public getSummaryAndCurrencies(body): Observable<any> {
    const summary = this.apiService.post(this.resourceUrl + '/get-activity', body);
    const otherCurrencies = this.apiService.get(this.resourceUrl + `/get-balance-currencies?cseg5=${body.cseg5}`);
    return forkJoin([summary, otherCurrencies]);
  }

  public getCurrencies(cseg5: string): Observable<any> {
    return this.apiService.get(this.resourceUrl + `/get-balance-currencies?cseg5=${cseg5}`);
  }

  public getSummary(body): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/get-activity', body);
  }

  public getActivityAndCurrencies(body): Observable<any> {
    const pendingTransactionBody = { ...body };
    delete pendingTransactionBody.assetInternalId;
    delete pendingTransactionBody.type;
    delete pendingTransactionBody.dateFormat;
    const allCurrencies = this.apiService.get(this.resourceUrl + `/get-balance-currencies?cseg5=${body.cseg5}`);
    const completedTransactions = this.apiService.post(this.resourceUrl + '/get-activity-completed', body);
    const pendingTransaction = this.apiService.post(this.resourceUrl + '/get-activity-pending', pendingTransactionBody);
    return forkJoin([allCurrencies, completedTransactions, pendingTransaction]);
  }

  public getActivityCompletedAndCurrencies(body): Observable<any> {
    const allCurrencies = this.apiService.get(this.resourceUrl + `/get-balance-currencies?cseg5=${body.cseg5}`);
    const completedTransactions = this.apiService.post(this.resourceUrl + '/get-activity-completed', body);
    return forkJoin([allCurrencies, completedTransactions]);
  }

  public getActivity(body): Observable<any> {
    const pendingTransactionBody = { ...body };
    delete pendingTransactionBody.assetInternalId;
    delete pendingTransactionBody.type;
    const completedTransactions = this.apiService.post(this.resourceUrl + '/get-activity-completed', body);
    const pendingTransaction = this.apiService.post(this.resourceUrl + '/get-activity-pending', pendingTransactionBody);
    return forkJoin([completedTransactions, pendingTransaction]);
  }

  public getActivityCompleted(body): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/get-activity-completed', body);
  }

  public downloadActivityCompleted(body): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/download-activity-completed', body);
  }

  public submitContribution(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/contribute-to-your-fund', body);
  }

  public getCharityByString(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/search-charity', body);
  }

  public getCountriesByString(term: string): Observable<any> {
    const params = new HttpParams().set('country', term || '');
    return this.apiService.get(this.resourceUrl + '/get-country', params);
  }

  public getCountryDetails(countryUuid: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/get-country-details', { countryUuid });
  }

  public addCharity(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/include-charity', body);
  }

  public submitTransaction(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/grant-to-a-fund', body);
  }

  public submitTransactionFundToFund(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/fund-to-fund-transfer', body);
  }

  public calculateGrant(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/calculate-grant', body);
  }

  public searchFundNumber(fundNumber: string): Observable<any> {
    const params = new HttpParams().set('fundNumber', fundNumber);
    return this.apiService.get(this.resourceUrl + '/check-fund-number', params);
  }

  public getFundsForTransfer(internalId: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/funds/transfer/${internalId}`);
  }

  public getCharityProjects(internalId: string): Observable<CharityProject[]> {
    return this.apiService.get(`${this.resourceUrl}/funds/${internalId}/projects`) as Observable<CharityProject[]>;
  }

  public calculate(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/calculate-fx', body);
  }

  public createFx(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/create-fx', body);
  }

  public getNextAction(body: any): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/get-next-action', body);
  }

  public repopulate(token: string): Observable<any> {
    const params = new HttpParams().set('token', token);
    const repopulateIgfData = this.apiService.get(this.resourceUrl + '/repopulate', params);
    const currencies = this.apiService.get(this.userResourceUrl + '/get-currencies');
    return forkJoin([repopulateIgfData, currencies]);
  }

  public generateDonateLink(fundNumber: string): Observable<any> {
    const params = new HttpParams().set('fundNumberInternalId', fundNumber);
    let headers = new HttpHeaders();
    environment.tenantId.match('sandbox')
      ? (headers = new HttpHeaders().set('tenant_dev', `${environment.environmentName}`))
      : undefined;
    return this.apiService.get(this.resourceUrl + '/generate-donate-link', params, headers);
  }

  public editDonateButton(body: DonateButton): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/edit-donate-button', body);
  }

  public getFundNumberFromHash(fundHash: string): Observable<any> {
    const params = new HttpParams().set('fundHash', fundHash);
    return this.apiService.get(this.resourceUrl + '/public-donate', params);
  }

  public getApplicationAssesmentFund(): Observable<any> {
    return this.apiService.get(this.resourceUrl + '/application-assessment');
  }

  public copyToMyInstance(myInstance: CopyToMyInstancePayload): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/copy-to-my-instance', myInstance);
  }

  public checkContribution(checkContribution: CheckContributionPayload): Observable<any> {
    return this.apiService.post(this.resourceUrl + '/check-contribution', checkContribution);
  }

  public checkMinimumAmount(currencySymbol: string): Observable<any> {
    return this.apiService.get(`${this.resourceUrl}/calculate-minimum-amount/${currencySymbol}`);
  }
}
