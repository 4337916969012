import { WIRING_INSTRUCTIONS_SWISS } from 'src/app/_core/contants/wiring-instructions';
import { EnvironmentNames } from 'src/app/_core/contants/environments';

export const environment = {
  // Assets
  primaryColor: 'rgb(168, 28, 7)',
  secondaryColor: 'rgb(243, 226, 223)',
  ternaryColor: 'rgb(168, 28, 7)',
  logo: 'assets/logos/tb.png',
  publicLogo: 'assets/logos/tb-public.png',
  lightDonateNowButtonLink:
    'https://5432341.app.netsuite.com/core/media/media.nl?id=425986&c=5432341&h=1mbtBQFEwVAtBRaoFfeWHwwCsvUzId6x_hwoHh_JVOsLdNyO',
  darkDonateNowButtonLink:
    'https://5432341.app.netsuite.com/core/media/media.nl?id=425985&c=5432341&h=v7eBj6dwbUKmDb1S9y6NJOyGV_WwAxNMQimOf1HlbkAsGVtm',
  lightDonateNowButtonSrc: 'assets/donate-buttons/swiss_light_donate_button.png',
  darkDonateNowButtonSrc: 'assets/donate-buttons/swiss_dark_donate_button.png',
  favicon:
    'https://images.squarespace-cdn.com/content/5ae9d1402714e568d499f07e/1526729411980-7A3HV54PI4EPUGJ4I7D3/logo.png?format=100w&content-type=image%2Fpng',
  wiringInstructions: WIRING_INSTRUCTIONS_SWISS,
  eftInstructions: null,
  stripeKey:
    'pk_test_51HuFiFEHLm9wiVf0xjLkqQAuqHA1m1tLNnCZD5sOENauhNkeFMKQYJWaUL89tTW5VBOxZFheVcyNxfjhucthVHRQ00aLWzSkWB',
  homeLink: 'https://www.trustbridgeglobal.com/',
  contact: 'http://trustbridgeglobal.com/contact',
  blog: 'http://www.trustbridgeglobal.com/blog',
  privacyPolicy: 'https://www.trustbridgeglobal.com/privacy',
  terms: 'https://www.trustbridgeglobal.com/terms',
  mailTo: 'admin@trustbridgeglobal.com',
  name: 'TrustBridge Global Foundation',
  // Server
  tenantId: 'swiss_test',
  server: 'https://test-sec.portal.benevatech.com/api',
  url: 'https://test-sec.portal.benevatech.com',
  // Frontend Logic
  environmentName: EnvironmentNames.SWISS,
  languages: ['en-swiss'],
  production: false,
  multiCurrency: true,
  redirectSourceUrl: null,
  backendClientId: 'swiss_test-portal-backend',
  authConfig: {
    issuer: 'https://test.sso.benevatech.com/auth/realms/BenevaTechTestSec',
    redirectUri: window.location.origin,
    clientId: 'swiss_test-portal-public',
    responseType: 'code',
    scope: 'openid profile email links',
    showDebugInformation: true,
    sessionChecksEnabled: true,
  },
};
