import { jwtDecode } from 'jwt-decode';
import * as moment from 'moment';
import { ContributeDonorDisplayData } from 'src/app/pages/donate/donate-form/donate-form.model';

import FundTypes from '../contants/fund-types';
import { IntervalOption } from '../contants/interval-option';
import Urls from '../contants/urls';
import { LoginResponseDto } from '../models/Auth';
import { KeycloakToken } from '../models/keycloak';
import StorageHelper from './storage.helper';

export default class SharedMethodsHelpers {
  static resetFormVariables(
    loading = true,
    showError = false,
    errorMessage = 'TOASTER_BASIC_ERROR',
    showSuccess = false
  ) {
    return {
      loading,
      showError,
      errorMessage,
      showSuccess,
    };
  }

  static dateToMilliseconds(date: any, emptyString = false, endOfDay = false) {
    return date ? (endOfDay ? moment(date).endOf('day').valueOf() : date.getTime()) : emptyString ? '' : null;
  }

  public static extractPinAndPasswordDetails(loginResponse: LoginResponseDto) {
    const goToChangePassword = !(
      !loginResponse.shouldResetPassword &&
      loginResponse.doesPinNumberExist &&
      loginResponse.changedPassword
    );
    return {
      goToChangePassword: goToChangePassword,
      details: {
        resetPass: loginResponse.shouldResetPassword,
        setPin: !loginResponse.doesPinNumberExist,
        changedPass: loginResponse.changedPassword,
      },
    };
  }

  public static decodeToken(token: string = StorageHelper.getToken()) {
    try {
      return jwtDecode<KeycloakToken>(token);
    } catch (Error) {
      return null;
    }
  }

  public static changeTabsForCharity(tabs, fundType) {
    for (const tab of tabs) {
      if (tab.name === Urls.GRANTS) {
        tab.displayName =
          fundType === FundTypes.CHARITY || fundType === FundTypes.PROJECT
            ? 'ACTIVITY_DISTRIBUTIONS'
            : 'ACTIVITY_GRANTS';
        break;
      }
    }
  }

  public static intervalFromPeriod(value: IntervalOption) {
    let startDate: string = null;
    let endDate: string = null;
    if (value) {
      switch (value) {
        case IntervalOption.ALL_TIME:
          startDate = moment.utc('01 January, 2019', ['DDMMMMY', 'MMMMDDY']).valueOf().toString();
          endDate = moment.utc().endOf('day').valueOf().toString();
          break;

        case IntervalOption.LAST_30_DAYS:
          startDate = moment.utc().subtract(30, 'days').startOf('day').valueOf().toString();
          endDate = moment.utc().endOf('day').valueOf().toString();
          break;

        case IntervalOption.LAST_90_DAYS:
          startDate = moment.utc().subtract(90, 'days').startOf('day').valueOf().toString();
          endDate = moment.utc().endOf('day').valueOf().toString();
          break;

        case IntervalOption.THIS_MONTH:
          startDate = moment.utc().startOf('month').valueOf().toString();
          endDate = moment.utc().endOf('day').valueOf().toString();
          break;

        case IntervalOption.LAST_MONTH:
          startDate = moment.utc().subtract(1, 'month').startOf('month').valueOf().toString();
          endDate = moment.utc().subtract(1, 'month').endOf('month').valueOf().toString();
          break;

        case IntervalOption.THIS_YEAR:
          startDate = moment.utc().startOf('year').valueOf().toString();
          endDate = moment.utc().endOf('day').valueOf().toString();
          break;

        case IntervalOption.LAST_YEAR:
          startDate = moment.utc().subtract(1, 'year').startOf('year').valueOf().toString();
          endDate = moment.utc().subtract(1, 'year').endOf('year').valueOf().toString();
          break;
        default:
          break;
      }
    }
    return {
      startDate: startDate,
      endDate: endDate,
    };
  }

  public static getCurrency(otherCurrencies, currencyId: string, field: string = 'id') {
    return otherCurrencies.find((currency) => currency[field] === currencyId);
  }

  public static getCurrencyBySymbol(currencies, currencySymbol: string) {
    return currencies.find((currency) => currency.symbol === currencySymbol);
  }

  public static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);

      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }

      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  public static floor(num: number): number {
    return Math.floor(num);
  }

  public static computeAmountToDisplay(amount: string | number): number {
    const regExp: RegExp = /\-*\w*,?\w*\.?[0-9]{0,2}/g;
    return +amount.toString().match(regExp)[0];
  }

  public static computeNewCharityPayload(
    formData: any,
    currencySymbol: string,
    loggedInName: string,
    fundName: string
  ) {
    return {
      ...formData,
      currencySymbol,
      loggedInName,
      fundName,
    };
  }

  public static computeSummaryData(form, charity: string): ContributeDonorDisplayData {
    return {
      charityName: charity,
      contributionInfo: form.countryAndCharityInfo,
      contributionType: form.contributionTypeInfo.contributionType.code,
      assetDescription: form.contributionTypeInfo.assetDescription,
      dafProvider: form.contributionTypeInfo.dafProvider,
      fundName: form.contributionTypeInfo.fundName,
      financialInfo: form.financialInfo,
      giverInfo: form.giverInfo,
      recurrencePattern: form.financialInfo.recurrenceForm?.recurrencePattern,
      startDate: form.financialInfo.recurrenceForm?.recurrenceRange?.startDate,
      endDate: form.financialInfo.recurrenceForm?.recurrenceRange?.endDate,
    };
  }

  public static sortCountries(array: any[]) {
    return array.sort((a, b) => {
      if (b.name < a.name) {
        return 1;
      } else if (b.name === a.name) {
        return 0;
      } else return -1;
    });
  }

  public static createBankInstructionsArray(
    instructionWithoutTaxDeduction: string,
    uuid1: string,
    instructionWithTaxDeduction?: string,
    uuid2?: string
  ) {
    return instructionWithTaxDeduction
      ? [
          {
            instructions: instructionWithoutTaxDeduction || '',
            taxDeduction: false,
            uuid: uuid1 || '',
          },
          {
            instructions: instructionWithTaxDeduction,
            taxDeduction: true,
            uuid: uuid2 || '',
          },
        ]
      : [
          {
            instructions: instructionWithoutTaxDeduction || '',
            taxDeduction: false,
            uuid: uuid1 || '',
          },
        ];
  }

  public static splitAndTakeFirst(item: string, separator: string): string {
    if (item.indexOf(separator) < 0) {
      return item;
    }

    return item.split(separator)[0].trim();
  }
}
