<div class="d-flex justify-content-between align-items-center dashboard my-4 pb-3 w-100">
  <!--Title-->
  <ng-container *ngIf="isMobile || !funds.length || isAdminPanel">
    <ng-container *ngIf="currentPageTitleData?.param; else noParam">
      <h3 class="pb-2 pt-2">{{ currentPageTitleData.text | translate: { param: currentPageTitleData.param } }}</h3>
    </ng-container>
    <ng-template #noParam>
      <h3 class="pb-2 pt-2">{{ currentPageTitleData?.text | translate }}</h3>
    </ng-template>
  </ng-container>
  <!-- View description -->
  <div class="justify-content-center view-description">
    <div *ngIf="currentFund && !isAdminPanel && !isPublic" class="text-center font-weight-bold">
      <ng-container [ngSwitch]="currentFund.fundType">
        <ng-container *ngSwitchCase="fundTypes.CHARITY">
          <span [class]="isSinngeber ? 'gold-text' : 'yellow-text'">{{ 'SUB_HEADER_VIEW_TITLE_CHARITY' | translate }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="fundTypes.DAF">
          <span class="blue-text">{{ 'SUB_HEADER_VIEW_TITLE_DAF' | translate }}</span>
        </ng-container>
        <ng-container *ngSwitchCase="fundTypes.PROJECT">
          <span class="blue-text">{{ 'SUB_HEADER_VIEW_TITLE_PROJECT' | translate }}</span>
        </ng-container>
      </ng-container>
    </div>
  </div>

  <!-- Funds -->
  <div
    #dropdown="bs-dropdown"
    class="btn-group"
    dropdown
    [insideClick]="true"
    (isOpenChange)="fundsOpen = !fundsOpen"
    *ngIf="currentFund && !isPublic && !isAdminPanel"
  >
    <button id="funds-button" dropdownToggle type="button" class="btn btn-primary" [class.dropdown-open]="fundsOpen" aria-controls="dropdown-nested">
      <span class="name" date-title="'name'" [title]="currentFund.fundName">
        {{ currentFund.fundName | truncate: 35 }} (#{{ currentFund.fundNumber }})</span
      >
      <i class="fas fa-caret-down"></i>
    </button>
    <ul id="dropdown-nested" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="funds-button">
      <ng-container *ngFor="let fund of funds; index as i">
        <li *ngIf="i > 0" class="divider dropdown-divider"></li>
        <div class="d-flex justify-content-center align-items-start">
          <li role="menuitem" class="dropdown-item" (click)="changeFundOrRedirect(fund); dropdown.hide()">
            <div>
              <h6 class="fundName font-weight-bold" date-title="'fundName'" [title]="fund.fundName">{{ fund.fundName | truncate: 35 }}</h6>
              <div class="fundLocation grey-text" date-title="'fundLocation'" [title]="fund.location">{{ fund.location | truncate: 35 }}</div>
              <span>{{ 'SUB_HEADER_DROPDOWN_BALANCE' | translate }}: {{ fund.closingBalance | number: '1.2-2' }} {{ fund.currencySymbol }}</span> |
              <span>{{ 'GENERAL_FUND' | translate }}: #{{ fund.fundNumber }}</span>
            </div>
          </li>
          <div class="ml-3" *ngIf="fund.projects.length > 0">
            <button
              type="button"
              class="btn mr-2"
              data-bs-toggle="collapse"
              [attr.data-bs-target]="'#projects' + i"
              aria-expanded="false"
              [attr.aria-controls]="'projects' + i"
            >
              <i class="fas fa-caret-down font-25"></i>
            </button>
          </div>
        </div>
        <div [id]="'projects' + i" class="collapse show">
          <ul>
            <li *ngFor="let project of fund.projects" role="menuitem">
              <a class="dropdown-item" (click)="changeFundOrRedirect(project); dropdown.hide()">
                <h6 class="fundName" date-title="'fundName'" [title]="project.fundName">{{ project.fundName | truncate: 35 }}</h6>
                <span
                  >{{ 'SUB_HEADER_DROPDOWN_BALANCE' | translate }}: {{ project.closingBalance | number: '1.2-2' }} {{ project.currencySymbol }}</span
                >
                |
                <span>{{ 'GENERAL_FUND' | translate }}: #{{ project.fundNumber | projectFundNumber }}</span>
              </a>
            </li>
          </ul>
        </div>
      </ng-container>
    </ul>
  </div>
</div>
