import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot } from '@angular/router';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import FundTypes from '../contants/fund-types';
import { Modals } from '../contants/modals';
import { Fund } from '../models/fund';
import { FundService } from '../services/fund.service';
import { ModalsService } from '../services/modals.service';
import { UserService } from '../services/user.service';
import { EnvironmentNames } from '../contants/environments';

@Injectable()
export class CanAccessGuard implements CanActivate {
  fund: Fund;

  // prettier ignore
  constructor(
    private fundService: FundService,
    private modalsService: ModalsService,
    private userService: UserService,
    private translateService: TranslateService
  ) {
    this.fundService.fund$.subscribe((fund: Fund) => {
      this.fund = fund;
    });
  }

  canActivate(_: ActivatedRouteSnapshot, __: RouterStateSnapshot) {
    if (!this.fund) return false;
    const currencyId: string = environment.environmentName !== EnvironmentNames.SWISS ? this.fund.primaryCurrencyId : this.fund.receiveGrantCurrency;
    const currencySymbol: string =
      environment.environmentName !== EnvironmentNames.SWISS ? this.fund.primaryCurrencySymbol : this.fund.receiveGrantCurrencySymbol;
    const noReceivingGrantsCurrency: boolean = [null, undefined, '0'].includes(currencyId);

    let popupMessage: string;

    if (noReceivingGrantsCurrency) popupMessage = 'DISTRIBUTION_WARNING_POPUP_NO_RECEIVING_CURRENCY';

    if (this.fund.fundType === FundTypes.CHARITY && popupMessage) {
      this.translateService
        .get(['DISTRIBUTION_WARNING_POPUP_TITLE', popupMessage], { currency: currencySymbol, email: environment.mailTo })
        .subscribe((translated: string[]) => {
          this.modalsService.openModal(Modals.DYNAMIC_CONTENT_MODAL, {
            displayName: translated['DISTRIBUTION_WARNING_POPUP_TITLE'],
            infoMessage: translated[popupMessage],
          });
        });
      return false;
    }
    return true;
  }
}
