import { User } from './user';

export interface IdentityClaims {
  exp: number;
  iat: number;
  auth_time: number;
  jti: string;
  iss: string;
  aud: string;
  sub: string;
  typ: string;
  azp: string;
  nonce: string;
  session_state: string;
  at_hash: string;
  acr: string;
  sid: string;
  email_verified: boolean;
  name: string;
  preferred_username: string;
  given_name: string;
  family_name: string;
  email: string;
}

export enum AccessRoles {
  ADMIN = 'ADMIN',
  USER = 'USER',
}

export interface KeycloakToken {
  // Keycloak Token
  resource_access: { [clientId: string]: { roles: [AccessRoles] } };
  links: string[];
  // API Token
  authorities: string[];
  shouldResetPassword: boolean;
  doesPinNumberExist: boolean;
  user_name: User;
}
