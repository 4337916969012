<label>
  <span class="font-weight-bold">{{ 'CURRENCY_DROPDOWN_LABEL' | translate }}</span>
  <span> ({{ otherCurrencies.length }} {{ 'GENERAL_ACTIVE' | translate }})</span>
</label>
<select class="form-control btn btn-default generalDropdown dropdown-toggle" [ngModel]="currency" (ngModelChange)="changeCurrency($event)">
  <option *ngFor="let currency of otherCurrencies" [value]="currency.id">
    <ng-container *ngIf="environment === environmentNames.CNCF; else others">
      {{ 'DAF' + ('CURRENCY_DROPDOWN_TOTAL' | translate) + '(' + currency.symbol + '): ' + (currency.balance | number: '1.2-2') }}
    </ng-container>
    <ng-template #others>
      {{ currency.symbol + ' ' + ('CURRENCY_DROPDOWN_TOTAL' | translate) + ':' + (currency.balance | number: '1.2-2') }}
    </ng-template>
  </option>
</select>
