import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild } from '@angular/core';
import { Validators } from '@angular/forms';
import { CurrencyMaskInputMode } from 'ngx-currency';
import { debounceTime, map } from 'rxjs/operators';
import { INPUT_TYPE } from 'src/app/_core/contants/Contribute';
import { CustomValidators } from 'src/app/_core/helpers/custom-validators.helpers';
import { CustomControl } from 'src/app/_core/models/CustomForms.model';

@Component({
  selector: 'app-text-input',
  templateUrl: './text-input.component.html',
  styleUrls: ['./text-input.component.scss'],
})
export class TextInputComponent implements OnInit, AfterViewInit, OnChanges {
  @ViewChild('defaultOption') defaultOption: ElementRef<HTMLElement>;
  @Input() control: CustomControl;
  @Input() type: INPUT_TYPE;
  @Input() dropdownItems: any[];
  @Input() skipValidation: boolean;
  @Input() hasTemplateValidation: boolean;
  @Input() autoClear: boolean = false;
  @Input() maxLength: number = null;

  @Output() search: EventEmitter<any> = new EventEmitter<any>();
  @Output() itemSelected: EventEmitter<any> = new EventEmitter<any>();
  @Output() focusOut: EventEmitter<any> = new EventEmitter<any>();

  inputTypes = INPUT_TYPE;
  inputMode: CurrencyMaskInputMode;
  searching: boolean;
  loadingResults: boolean;

  constructor() {}

  ngOnInit(): void {
    if (!this.type) {
      this.type = this.control.type ? this.control.type : INPUT_TYPE.TEXT;
    }
    if (this.type === INPUT_TYPE.AUTOCOMPLETE) {
      this.handleLocalValueChange();
      this.handleGlobalValueChange();
    }
    if (this.type === INPUT_TYPE.CURRENCY) {
      this.inputMode = CurrencyMaskInputMode.NATURAL;
    }
  }

  ngAfterViewInit(): void {
    if (this.type === INPUT_TYPE.DROPDOWN) {
      setTimeout(() => {
        this.defaultOption?.nativeElement.setAttribute('disabled', 'true');
      }, 1000);
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.dropdownItems) {
      this.loadingResults = false;
    }
  }

  handleLocalValueChange(): void {
    this.control.auxControl.valueChanges
      .pipe(
        map((val) => {
          this.loadingResults = true;
          return val;
        }),
        debounceTime(500)
      )
      .subscribe((value) => {
        this.displayList(value);
      });
  }

  handleGlobalValueChange(): void {
    if (this.control.value) {
      this.control.auxControl.patchValue(this.extractValueToDisplay(this.control.value), { emitEvent: false });
      this.checkDisabledStatus();
    }
    this.control.valueChanges.subscribe((value) => {
      if (value) {
        this.control.auxControl.patchValue(this.extractValueToDisplay(value), { emitEvent: false });
        this.checkDisabledStatus();
      } else {
        this.checkDisabledStatus();
        this.control.auxControl.patchValue(null, { emitEvent: false });
      }
    });
  }

  extractValueToDisplay(value): string {
    if (value.symbol) {
      return value.symbol;
    } else if (value.fundNumber) {
      return `${value.name} (#${value.fundNumber})`;
    } else {
      return value.name
        .split(' ')
        .map((word) => {
          const wordArray = word.split('');
          const capital = wordArray[0];
          wordArray.splice(0, 1);
          return capital + wordArray.join('').toLowerCase();
        })
        .join(' ');
    }
  }

  checkDisabledStatus(): void {
    if (this.control.disabled) {
      this.control.auxControl.disable({ emitEvent: false });
    } else if (this.control.auxControl.disabled) {
      this.control.auxControl.enable({ emitEvent: false });
    }
  }

  displayList(value: string = ''): void {
    !this.loadingResults && (this.loadingResults = true);
    this.search.emit(value);
    this.searching = true;
  }

  hideList(): void {
    this.searching = false;
  }

  select(item: any): void {
    this.searching = false;
    this.control.patchValue(item);
    if (this.autoClear && this.control.value) {
      this.select(null);
    }
    this.itemSelected.emit(item);
  }

  clear(): void {
    this.select(null);
  }

  get required(): boolean {
    return this.control.hasValidator(Validators.required) || this.control.hasValidator(CustomValidators.required);
  }
}
