import { Observable, of } from 'rxjs';
import { environment } from 'src/environments/environment';

import { inject, Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  CanLoad,
  CanLoadFn,
  Data,
  Route,
  Router,
  RouterStateSnapshot,
  UrlSegment,
  UrlTree,
} from '@angular/router';

import { AuthService } from '../api/auth.service';
import Urls from '../contants/urls';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import StorageHelper from '../helpers/storage.helper';
import { AccessRoles } from '../models/keycloak';
import { User } from '../models/user';
import { KeycloakService } from '../services/keycloak.service';
import { RolesService } from '../services/roles.service';
import { UserService } from '../services/user.service';

// @Injectable()
// export class NoAuthGuard implements CanActivate, CanLoad {
//   // prettier ignore
//   constructor(private router: Router, private authService: AuthService, private userService: UserService) {}

//   canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> | boolean {
//     return this.hasAccess(state);
//   }

//   canLoad(route: Route, segments: UrlSegment[]): Observable<boolean | UrlTree> {
//     return this.hasAccess(segments, route.data);
//   }

//   hasAccess(urlData: RouterStateSnapshot | UrlSegment[], routeData?: Data): Observable<boolean | UrlTree> {
//     let url: string, lastParam: string;
//     if (urlData instanceof RouterStateSnapshot) {
//       url = urlData.url;
//       lastParam = url.split('/').reverse()[0];
//     } else if (urlData[0] instanceof UrlSegment) {
//       url = urlData.join('/');
//       lastParam = urlData[urlData.length - 1].path;
//     }

//     if (StorageHelper.getToken()) {
//       this.authService.getUserDetails().subscribe((user: User) => {
//         if (Object.values(Urls).findIndex((url) => url === lastParam) < 0) {
//           this.userService.logout(url);
//           return true;
//         }
//         if (user?.email) {
//           if (SharedMethodsHelpers.decodeToken().isEmployee && !user.funds.length) {
//             this.router.navigate([`${Urls.APP}/${Urls.ADMIN}`]);
//             return false;
//           }
//           this.router.navigate([Urls.APP]);
//           return false;
//         }
//         return true;
//       });
//     } else {
//       return of(true);
//     }
//   }
// }

export const noAuthGuard: CanLoadFn = async (route: Route): Promise<boolean | UrlTree> => {
  const keycloakService = inject(KeycloakService);
  const router = inject(Router);
  const rolesService = inject(RolesService);
  const authService = inject(AuthService);

  const { environmentName, homeLink } = environment;
  const appRedirect = router.createUrlTree([Urls.APP]);
  const adminRedirect = router.createUrlTree([Urls.APP, Urls.ADMIN]);

  if (keycloakService.getIsLoggedIn()) {
    const user = await authService.getUserDetails().toPromise();
    const hasAdmin = rolesService.hasRole(AccessRoles.ADMIN);

    if (hasAdmin && !user?.funds.length) return adminRedirect;

    return appRedirect;
  }

  if (route.data?.environments.indexOf(environmentName) >= 0) return true;

  window.location.href = homeLink;

  return false;
};
