import { Injectable } from '@angular/core';

import { environment } from '../../../environments/environment';
import SharedMethodsHelpers from '../helpers/shared-methods.helpers';
import { AccessRoles } from '../models/keycloak';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class RolesService {
  roles: AccessRoles[] | null = null;

  constructor(private keycloakService: KeycloakService) {}

  hasRole(role: AccessRoles): boolean {
    this._retrieveTokenRoles();
    return this.roles.includes(role);
  }

  hasRoles(roles: AccessRoles[]): boolean {
    this._retrieveTokenRoles();
    return roles.some((role) => this.hasRole(role));
  }

  private _retrieveTokenRoles(): void {
    if (!this.roles) {
      const accessToken = this.keycloakService.getAccessToken();
      const decodedToken = SharedMethodsHelpers.decodeToken(accessToken);
      const { roles } = decodedToken.resource_access?.[environment.backendClientId];
      this.roles = roles;
    }
  }
}
