export class ConfigSetting {
  customLinkLabel: string;
  customLinkUrl: string;
  homeButtonUrl: string;
  defaultAnonymousContributionValue: boolean;
  dateFormat: string;
  announcement: string;

  constructor(settingData: any) {
    this.customLinkLabel = settingData.customLinkLabel;
    this.customLinkUrl = settingData.customLinkUrl;
    this.homeButtonUrl = settingData.homeButtonUrl;
    this.defaultAnonymousContributionValue = settingData.defaultAnonymousContributionValue;
    this.dateFormat = settingData.dateFormat;
    this.announcement = settingData.announcement;
  }
}
