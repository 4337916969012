import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { environment } from '../../../../environments/environment';
import { AppLink, tenants } from './app-switcher.model';

@Component({
  selector: 'app-switcher',
  template: `<ul ngbNav #nav="ngbNav" class="nav-tabs justify-content-center mr-3">
    <li class="nav-item nav-auto mx-2" ngbDropdown>
      <a class="grey-link font-weight-bold" (click)="(false)" ngbDropdownToggle>{{
        'HEADER_APP_SWITCHER_DROPDOWN_LABEL' | translate
      }}</a>
      <section ngbDropdownMenu>
        <ng-container *ngFor="let category of updatedTenants">
          <div class="d-flex align-items-center py-1">
            <span class="category px-2">{{ category.name | translate }}</span>
            <div class="category-line w-100"></div>
          </div>
          <button
            *ngFor="let link of category.links"
            [class.pointer]="!isActiveApplication(link)"
            ngbDropdownItem
            (click)="redirectTo(link)"
          >
            <div class="d-flex justify-content-between px-3 align-items-center">
              <span>{{ link | transform : getLabel | translate }}</span>
              <i *ngIf="isActiveApplication(link)" class="fa fa-check text-success"></i>
            </div>
          </button>
        </ng-container>
      </section>
    </li>
  </ul>`,
  styleUrls: ['../header.component.scss', './app-switcher.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppSwitcherComponent implements OnInit {
  @Input() tokenLinks: string[] = [];

  updatedTenants = [];

  ngOnInit(): void {
    this.updatedTenants = tenants
      .map((tenant) => {
        return {
          ...tenant,
          links: this._groupBy(
            this.tokenLinks.map((link) => JSON.parse(link)),
            tenant.prefix
          ),
        };
      })
      .filter((tenant) => tenant.links.length > 0);
  }

  redirectTo(link: AppLink): void {
    if (this.isActiveApplication(link)) return;
    const url = Object.values(link)[0];
    window.open(url, '_self');
  }

  getLabel(link: AppLink): string {
    const hasObjectKey = (link: AppLink, partial: string) => Object.keys(link).some((key) => key.includes(partial));

    if (hasObjectKey(link, 'portal')) return 'HEADER_APP_SWITCHER_PORTAL';
    if (hasObjectKey(link, 'gs')) return 'HEADER_APP_SWITCHER_GIVINGSPACE';
  }

  isActiveApplication(link: AppLink): boolean {
    const linkDomain = new URL(Object.values(link)[0]).hostname;
    const environmentDomain = new URL(environment.url).hostname;
    return environmentDomain.includes(linkDomain);
  }

  private _groupBy(array: AppLink[], prefix: string) {
    return array.reduce((acc, cur) => {
      const matchingKey = Object.keys(cur).find((key) => key.startsWith(prefix));
      if (matchingKey) {
        acc.push({ [matchingKey]: cur[matchingKey] });
      }
      return acc;
    }, []);
  }
}
