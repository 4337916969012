import { Observable, of, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';

import { environment } from '../../../environments/environment';
import { Configuration } from '../../app.constants';
import StorageHelper from '../helpers/storage.helper';
import { UserService } from '../services/user.service';
import { KeycloakService } from '../services/keycloak.service';

@Injectable()
export class Interceptor implements HttpInterceptor {
  private readonly tenantId = this.configService.tenantId;
  constructor(private configService: Configuration, private injector: Injector) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    let newHeaders = req.headers
      .set('Access-Control-Allow-Origin', '*')
      .set('Cache-Control', 'no-cache')
      .set('Pragma', 'no-cache')
      .set('tenantId', this.tenantId);

    // const token = StorageHelper.getToken();
    const token = this.injector.get(KeycloakService).getAccessToken();

    if (token) {
      newHeaders = newHeaders.set('Content-Type', 'application/json').set('Authorization', 'Bearer ' + token);
    }

    const clonedReq = req.clone({ headers: newHeaders });
    const updatedReq = req.url.includes(environment.authConfig.issuer) ? req : clonedReq;

    return next
      .handle(
        window.location.origin.includes('localhost:') && updatedReq.url.includes('/api/')
          ? updatedReq.clone({ url: `https://thingproxy.freeboard.io/fetch/${encodeURIComponent(updatedReq.url)}` })
          : updatedReq
      )
      .pipe(
        catchError((error) => {
          console.log(error);
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 400:
                if (error?.error?.error_description?.indexOf('Invalid access token') > -1) {
                  this.injector.get(UserService).killSession();
                  // location.reload();
                  return of(error);
                } else {
                  return throwError(error);
                }
              case 401:
                this.injector.get(UserService).killSession();
                // location.reload();
                return of(error);
              case 502:
              // this.router.navigate([Urls.SERVER_ERROR], { skipLocationChange: true });
              default:
                return throwError(error);
            }
          }
        })
      );
  }
}
