export const tenants = [
  { prefix: 'swiss', name: 'HEADER_APP_SWITCHER_SWISS', links: [] },
  { prefix: 'egcc', name: 'HEADER_APP_SWITCHER_EGCC', links: [] },
  { prefix: 'igf', name: 'HEADER_APP_SWITCHER_IGF', links: [] },
  { prefix: 'glf', name: 'HEADER_APP_SWITCHER_GLF', links: [] },
  { prefix: 'sinngeber', name: 'HEADER_APP_SWITCHER_SGH', links: [] },
  { prefix: 'cncf', name: 'HEADER_APP_SWITCHER_CNCF', links: [] },
];

export interface AppLink {
  [key: string]: string;
}
