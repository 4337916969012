import { BehaviorSubject } from 'rxjs';

import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from '../api/auth.service';
import Urls from '../contants/urls';
import StorageHelper from '../helpers/storage.helper';
import { User } from '../models/user';
import { FundService } from './fund.service';
import { KeycloakService } from './keycloak.service';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  private _currentUser: User = null;
  private _selectedCurrencyId: string;
  currentUser$ = new BehaviorSubject<User>(null);

  // prettier ignore
  constructor(private router: Router, private authService: AuthService, private fundService: FundService, private keycloakService: KeycloakService) {}

  getCurrentUser(): User {
    return this._currentUser;
  }

  setCurrentUser(currentUser: User) {
    this._currentUser = currentUser;
    this.currentUser$.next(this._currentUser);
    this._currentUser?.funds?.length && this.fundService.initUserFunds(this._currentUser.funds);
  }

  isLoggedIn(): boolean {
    return !!StorageHelper.getToken() || this.keycloakService.getIsLoggedIn();
  }

  logout(redirectUrl?: string) {
    if (this.isLoggedIn()) {
      this.killSession();
    }
    // this.isLoggedIn() &&
    //   this.authService.logout().subscribe(() => {
    //     this.fundService.fundChanged(null);
    //     this.killSession(redirectUrl);
    //   });
  }

  killSession(redirectUrl?: string) {
    StorageHelper.killSession();
    this.setCurrentUser(null);
    this.keycloakService.logout();
    // const url = redirectUrl ? redirectUrl : Urls.AUTH;
    // this.router.navigate([url]);
  }

  setSelectedCurrencyId(currencyId: string): void {
    this._selectedCurrencyId = currencyId;
  }

  get selectedCurrencyId(): string {
    return this._selectedCurrencyId;
  }
}
